.people_page {}

.people_page .intro {
  font-size: 1.25em;
  line-height: 2em;
  text-align: center;
  padding: 2em;
  color: #4D4D4D;
}

.people_page .person > div {
  display: flex;
  flex-direction: row;
  padding: 1em;
}

.people_page li {
  list-style-type: none;
}

.people_page svg {
  margin: 0.5em;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.5em;
}

.people_page .messages {
  display: flex;
  flex-direction: column;
  padding: 2em;
  justify-content: center;
  align-items: center;
}

.people_page .messages span {
  color: #4D4D4D;
  text-align: center;
  margin: 0.5em;
}
